import { TextField, Typography, Button, Modal, Box } from "@mui/material";
import { React, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/LocalPhone";
// import fbLogo from "../../images/socialmedia_icon/facebook.png";
// import twLogo from "../../images/socialmedia_icon/twitter.png";
// import linLogo from "../../images/socialmedia_icon/linkedin.png";
// import ytLogo from "../../images/socialmedia_icon/youtube.png";
import fbLogo from "../../images/socialmedia_icon/ic_f.svg";
import linLogo from "../../images/socialmedia_icon/ic_l.svg";
import twLogo from "../../images/socialmedia_icon/ic_t.svg";
import ytLogo from "../../images/socialmedia_icon/ic_y.svg";
import Footer from "./Footer";
import { ScrollToTop } from "react-simple-scroll-up";
import AppbarHead from "./AppbarHead";
import { useLocation } from "react-router-dom";
import { Colors, Fonts } from "../../constants";
import { styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';






const style =(theme)=>({
  position: 'absolute',
  top: '50%',
  left: '50%',    
  transform: 'translate(-50%, -50%)',   
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,

  
  [theme.breakpoints.down('lg')]:{
    width:350
  },
  [theme.breakpoints.up('lg')]:{
    width:600
  }
}) ;


const DivStyle4 = styled("div")(({ theme }) => ({
  margin: "0 auto",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    width: "70%",
  },
}));




export default function RequestFormSubmit(props) {
  const location = useLocation();

  const [errorName, seterrorName] = useState(false);
  const [errorEmail, seterrorEmail] = useState(false);
  const [errorContactNo, seterrorContactNo] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [productName] = useState(props.prodName);



  const contactDetails = ["Name", "Email", "Contact Number"];

  const [data, setdata] = useState({
    Name: "",
    Email: "",
    "Contact Number": "",
    message: "",
  });
  const textfieldColor = (theme) => ({
    ".MuiFormLabel-root.Mui-focused": {
      color: `${Colors.MAIN_COLOR} !important`,
    },
    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${Colors.MAIN_COLOR} !important`,
    },

    [theme.breakpoints.up("lg")]: {
      width: 350,
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  });

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  const handleChange = () => {
    const echecking =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    {
      data["Name"] === ""
        ? seterrorName(true)
        : data["Email"] === "" || !echecking.test(data["Email"])
        ? seterrorEmail(true)
        : data["Contact Number"] === ""
        ? seterrorContactNo(true)
        : data["message"] === ""
        ? seterrorMessage(true)
        : seterrorMessage(false);
    }

    if (
      data["Name"] != "" &&
      data["Email"] != "" &&
      echecking.test(data["Email"]) &&
      data["Contact Number"] != "" &&
      data["message"] != ""
    ) {
      setdata({
        Name: "",
        Email: "",
        "Contact Number": "",
        message: "",
      });
      handleClose();
    }
  };

  const handleChangeSubmit = (e, text) => {
    const re = /^[0-9\b]+$/;

    if (text === "Name") {
      setdata({ ...data, Name: e.target.value });
      seterrorName(false);
    } else if (text === "Email") {
      console.log("working");

      setdata({ ...data, Email: e.target.value });
      seterrorEmail(false);
    } else if (text === "Contact Number") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setdata({
          ...data,
          "Contact Number": (e.target.value = e.target.value.slice(0, 10)),
        });
        seterrorContactNo(false);
      }
    }
  };


 




  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    setdata({ ...data, selectProduct: productName.productName });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ width: "100%", marginTop: 25 }}>
        <DivStyle4>
          <Typography
            variant="h4"
            style={{ marginTop: 20, fontWeight: "600", fontFamily: Fonts.MAIN_FONT ,color:Colors.MAIN_COLOR }}
          >
            Need this Product? Contact us:
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "justify", fontFamily: Fonts.MAIN_FONT, marginTop: 1 }}
          >
            {" "}
            If you like and want this product, Please feel free to contact us.
            Also we can add/update new features and design changes in this
            product if you want.
          </Typography>

          <Typography
            variant="body1"
            sx={{ textAlign: "justify", fontFamily: Fonts.MAIN_FONT, marginTop: 1 }}
          >
            To discuss about this product and our other services, please mail us
            on
            <span
              style={{ color: " #3B7CFE", cursor: "pointer" }}
              onClick={() => {
                window.open("mailto:support@ncpli.com");
              }}
            >
              {" "}
              support@ncpli.com
            </span>{" "}
            or call
            <span style={{ cursor: "pointer", color: " #3B7CFE" }} onClick={() => { window.open("tel:7305023014", "_self"); }} > +91 73050 23014 </span>
          </Typography>
          <Button
            style={{
              backgroundColor: "#ED514A",
              color: "white",
              fontWeight: "600",
              fontFamily: "nunito",
              marginTop: 15,
              textTransform: "none",
              width:150,
            }}

            onClick={handleOpen} 

          >
            Request a Demo
          </Button>
        </DivStyle4>
      </div>



     <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: Colors.MAIN_COLOR,
            padding: "3% 5% 3% 5%",
          }}
        >
          <Typography variant="h6" sx={{ color: "white" }}>
            Demo Request
          </Typography>
          <CloseIcon
            onClick={handleClose}
            style={{ color: "white", cursor: "pointer" }}
          />
        </div>

        <div
          style={{
            margin: "0 auto",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: width < 1200 ? "column" : "row",
            justifyContent: "space-between",
            padding: "3% 5% 3% 5%",
          }}
        >
          <div>
            {contactDetails.map((text) => (
              <div>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "300",
                    fontFamily: Fonts.MAIN_FONT,
                    marginTop: 7,
                  }}
                  multiline
                >
                  {text} <span style={{ color: "#ED514A" }}>*</span>
                </Typography>
                <TextField
                  sx={textfieldColor}
                  size="small"
                  value={data[text]}
                  onChange={(e) => {
                    handleChangeSubmit(e, text);
                  }}
                  error={
                    text === "Name"
                      ? errorName
                      : text === "Email"
                      ? errorEmail
                      : text === "Contact Number"
                      ? errorContactNo
                      : null
                  }
                  helperText={
                    text === "Name"
                      ? !errorName
                        ? ""
                        : "Please enter your Name"
                      : text === "Email"
                      ? !errorEmail
                        ? ""
                        : "Please enter your valid mail id"
                      : text === "Contact Number"
                      ? !errorContactNo
                        ? ""
                        : "Please enter Contact No."
                      : null
                  }
                />
              </div>
            ))}
            <Typography
              variant="body1"
              style={{ marginTop: 7, fontFamily: Fonts.MAIN_FONT }}
            >
              Products
            </Typography>
            <TextField
              sx={textfieldColor}
              size="small"
              value={data.selectProduct}
            ></TextField>
            <Typography
              variant="body1"
              style={{ marginTop: 7, fontFamily: Fonts.MAIN_FONT }}
            >
              Message <span style={{ color: "#ED514A" }}>*</span>{" "}
            </Typography>
            <TextField
              sx={textfieldColor}
              multiline
              rows={2}
              value={data.message}
              error={errorMessage}
              helperText={!errorMessage ? "" : "Please type your message"}
              onChange={(e) => {
                setdata({ ...data, message: e.target.value });
                seterrorMessage(false);
              }}
            />
            <div style={{ display: "flex", justifyContent: "left" }}>
              <Button
                sx={{
                  backgroundColor:Colors.MAIN_COLOR,
                  color: "white",
                  fontWeight: "600",
                  fontFamily: Fonts.MAIN_FONT,
                  marginTop: 1,
                  textTransform: "none",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "20%",
                    xl: "20%",
                  },
                  "&:hover": { backgroundColor:Colors.MAIN_COLOR },
                }}
                onClick={handleChange}
              >
                Submit
              </Button>
            </div>
          </div>
          <div>
            <Typography
              style={{
                marginTop: 10,
                fontWeight: "bold",
                fontFamily: Fonts.MAIN_FONT
              }}
            >
              Contact{" "}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                alignItems: "center",
              }}
            >
              <EmailIcon />
              <Typography
                style={{
                  fontFamily: Fonts.MAIN_FONT,
                  paddingLeft: 4,
                  color: " #3B7CFE",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("mailto:support@ncpli.com");
                }}
              >
                support@ncpli.com
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                alignItems: "center",
              }}
            >
              <PhoneIcon />
              <Typography
                style={{ fontFamily:Fonts.MAIN_FONT, paddingLeft: 4 }}
                onClick={() => {
                  window.open("tel:04622585979");
                }}
              >
                0462 - 2585979{" "}
              </Typography>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
    </>
  );
}
