import { React, useState, useMemo, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { ScrollToTop } from "react-simple-scroll-up";
import AppbarHead from "../SubComponents/AppbarHead";
import "animate.css/animate.min.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Colors } from "../../constants";
import about1 from "../../images/allPageBanner/abouts-03.png";

import eContentImg from "../../images/servicePageImages/EContentDevelopment/eContentDevelopment.png";

import frontEndArchitecture from "../../images/servicePageImages/WebApplicationDevlopment/front-end-architecture.svg";
import testing from "../../images/servicePageImages/WebApplicationDevlopment/testing-performance.svg";
import responsive from "../../images/servicePageImages/WebApplicationDevlopment/responsive.svg";
import uiuxdesign from "../../images/servicePageImages/WebApplicationDevlopment/uiuxdesign.svg";
import cmsEcommerce from "../../images/servicePageImages/WebApplicationDevlopment/cms-ecommerce.svg";
import javascriptSolutions from "../../images/servicePageImages/WebApplicationDevlopment/javascript-solutions.svg";

import eContentDevelopmentContents from "../StaticTextContents/productContents/eContentDevelopmentContents";
import ComputerIcon from "@mui/icons-material/Computer";

import Footer from "../SubComponents/Footer";
import BannerAll from "../SubComponents/BreadCrumbComponent";
import { Fonts } from "../../constants";

export default function GraphicDesign() {

    const graphicDesignText = ["2D and 3D Animations", "Brochure", "Flyer", "Business Card", "Social Media Posters", "Promotional Posters", "Menu Card Designs"]
  const BreadcrumbStyle = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
  }));

  const DivStyle1 = styled("div")(({ theme }) => ({
    display: "flex",
    paddingLeft: "5%",
    paddingRight: "5%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: "15%",
      paddingRight: "15%",
    },
  }));
  const DivStyle2 = styled("div")(({ theme }) => ({
    ".container": {
      position: "relative",
      marginTop: 10,
      "&:hover": {
        opacity: 1,
      },
    },

    ".overlay": {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "100%",
      alignItems: "flex-end",
      display: "flex",
      transition: ".5s ease",
      opacity: 0,
      padding: "20px",
      "&:hover": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "0 auto",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      margin: "0 auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: "47%",
    },
  }));
  const DivStyle3 = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "0 auto",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "47%",
    },
  }));

  const cardStyle = (theme) => ({
    [theme.breakpoints.only("sm")]: {
      height: 530,
      marginTop: 3,
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 420,
      marginTop: 3,
    },
    [theme.breakpoints.only("md")]: {
      height: 400,
    },
    [theme.breakpoints.up("md")]: {
      height: 350,
    },

    [theme.breakpoints.up("lg")]: {
      height: 410,
    },
    [theme.breakpoints.up("xl")]: {
      height: 410,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ScrollToTop bgColor="green" symbol="&#8593;" strokeFillColor="white" />
      <AppbarHead dataParent={{ appBtnText: "Services" }} />

      <BannerAll
        dataParent={{
          title: "Graphic Design",
          subTitle: "",
          path: ["Home", "Services", "Graphic Design"],
        }}
      />

      <div style={{ width: "100%", marginTop: "2%" }}>
        <DivStyle1>
          <DivStyle2>
            <Typography
              variant="h4"
              style={{
                textAlign: "justify",
                marginTop: 20, fontFamily:Fonts.MAIN_FONT,
                color:Colors.MAIN_COLOR
              }}
            >
              Design Makes Greater
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "justify",
                marginTop: 20, fontFamily:Fonts.MAIN_FONT,
              }}
            >
              Netcom Computers is a Professional 2D and 3D Animations designing services in Tirunelveli, India. That’s why a professionally designed Animations can bring out an enormous difference to your business.   Netcom Computers is best in 2D, 3D Animations, Branding, Brochure, Invitation, Flyer, and Packages.
            </Typography>

            <Typography
              variant="h5"
              style={{
                textAlign: "justify",
                marginTop: 30, fontFamily:Fonts.MAIN_FONT,
                color:Colors.MAIN_COLOR
              }}
            >
              Our Graphic Design Servies are 
            </Typography>
            

            {graphicDesignText.map((text) => (
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
              >
                <CheckCircleIcon style={{ color: Colors.MAIN_COLOR }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily:Fonts.MAIN_FONT,
                    paddingLeft: 1,
                    textAlign: "justify",
                    marginBottom: 1,
                  }}
                >
                  {" "}
                  {text}
                </Typography>
              </div>
            ))}
          </DivStyle2>

          <DivStyle3>
            <div className="container">
              <img
                width="100%"
                style={{ top: 0, left: 0, height: "350px" }}
                src={eContentImg}
              ></img>
            </div>
          </DivStyle3>
        </DivStyle1>
      </div>

  

    
      <Footer />
    </>
  );
}
